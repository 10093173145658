
import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { OverlayTrigger,Tooltip,Popover } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

const InfosPopover = (props) => {
    const t = useTranslate();

    return(<>
         <OverlayTrigger 
            placement="top"
            overlay={
                <Popover title="Popover right" className='p-1'>
                    {props.message}
                </Popover>
            }
        >
            <span style={{cursor:'pointer'}}><FontAwesomeIcon className='text-primary' icon={faQuestionCircle} /></span>
        </OverlayTrigger>
    </>)
}

export default InfosPopover;